import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";

import { CountryInterface } from "@countries/ports/interfaces";

export abstract class CountryAbstraction {
  abstract list(query: string): Observable<RespListInterface<CountryInterface>>;
  abstract create(country: CountryInterface): Observable<RespInterface<CountryInterface>>;
  abstract update(country: CountryInterface): Observable<RespInterface<string>>;
  abstract read(id: string): Observable<RespInterface<CountryInterface>>;
  abstract delete(id: string): Observable<RespInterface<CountryInterface>>;
}
